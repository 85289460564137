<template>
  <div>
    <v-btn
      class="mt-2 primary-button-mini"
      data-cy="enter-bpq-score"
      @click.stop="openDialog()"
    >
      Skifteomkostninger
    </v-btn>
    <v-dialog v-model="switchingCostsDialog">
      <v-container fluid>
        <v-card class="pa-6">
          <v-container>
            <h1>{{ offer.sender.companyName }}</h1>
            <h2>
              Indtast skifteomkostninger
            </h2>
            <br />
            <v-card-text class="pl-0">
              <div>
                <div>
                  <v-row>
                    <v-col cols="2">
                      <strong>Skifteomkostninger</strong>
                    </v-col>
                    <v-col>
                      <NumericInput
                        v-model="switchingCosts"
                        :decimals="2"
                        hint=""
                        suffix="DKK"
                        :disabled="readonly"
                        color="primary"
                        variant="outlined"
                        density="compact"
                        flat
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2">
                      <strong>Begrundelse</strong>
                    </v-col>
                    <v-col>
                      <v-textarea
                        class="mt-1"
                        variant="outlined"
                        color="primary"
                        flat
                        density="compact"
                        v-model="switchingCostsNote"
                      />
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-card-text>
            <br />
            <div class="d-flex justify-center">
              <v-card-actions v-if="!readonly">
                <v-btn
                  class="primary-button mr-2"
                  :loading="isLoading"
                  :disabled="!switchingCostsChanged || !validNumberOfDecimals"
                  @click="save()"
                >
                  Gem
                </v-btn>
                <v-btn class="secondary-button ml-2" @click="cancelDialog()">
                  Annuller
                </v-btn>
              </v-card-actions>
              <v-card-actions v-if="readonly">
                <v-btn class="primary-button ml-2" @click="cancelDialog()">
                  Luk
                </v-btn>
              </v-card-actions>
            </div>
          </v-container>
        </v-card>
      </v-container>
    </v-dialog>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useBackend, useStore, NumericInput } from '@dims/components';
import { Offer5086, OfferData5086 } from '@/models';

const emit = defineEmits<{ offerChanged: [string] }>();
const { offer, readonly = false } = defineProps<{
  offer: Offer5086,
  readonly?: boolean }>();
const backend = useBackend();
const store = useStore();

const switchingCosts = ref<number>(0);
const switchingCostsNote = ref<string>('');
const isLoading = ref(false);
const switchingCostsDialog = ref(false);

function openDialog() {
  switchingCosts.value = offer.data.switchingCosts ?? 0;
  switchingCostsNote.value = offer.data.switchingCostsNote ?? '';
  switchingCostsDialog.value = true;
}

async function save() {
  isLoading.value = true;
  try {
    await saveSwitchingCosts();
    cancelDialog();
  } catch (error) {
    console.error(error);
    store.setSnackbarText('Kan ikke hente gemme.');
  } finally {
    isLoading.value = false;
    // refresh offers
    emit('offerChanged', offer.id);
  }
}

async function saveSwitchingCosts() {
  const offerData: Partial<OfferData5086> = { };
  offerData.switchingCosts = switchingCosts.value;
  offerData.switchingCostsNote = switchingCostsNote.value;
  const updatedOffer = await backend.offerService.updateOffer(
    offer,
    {
      data: offerData,
    },
  );
  return updatedOffer;
}

const switchingCostsChanged = computed((): boolean => {
  const od = offer.data;
  return (od.switchingCosts !== switchingCosts.value) || (od.switchingCostsNote !== switchingCostsNote.value);
});

function cancelDialog() {
  switchingCosts.value = 0;
  switchingCostsDialog.value = false;
}

const validNumberOfDecimals = computed((): boolean => {
  if (!switchingCosts.value && switchingCosts.value !== 0) return false;
  const stringValue = switchingCosts.value.toString();
  const match = stringValue.match(/^[0-9]+.([0-9]+)?$/);
  if (!match) return true;
  const decimals = match[1];
  if (decimals) {
    if (decimals.length > 2) {
      return false;
    }
  }
  return true;
});

</script>
