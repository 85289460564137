import { useStore } from '@dims/components';
import { defineStore } from 'pinia';
import { DraftTender5086 } from '@/models';

export const useStore5086 = defineStore('5086', {
  getters: {
    draftTender() {
      const store = useStore();
      return store.draftTender as DraftTender5086 | null;
    },
  },
  actions: {
    setDraftTender(tender: DraftTender5086) {
      const store = useStore();
      store.setDraftTender(tender);
    },
  },
});

export default useStore5086;
