import {
  Offer,
  AnswerSetBuilder,
} from '@dims/components';
import { Offer5086, Tender5086 } from '@/models';
import { getCalculator, qualityRatioPercentage } from './utils5086';

export default {
  getOfferPrice(offer: Offer): number {
    return offer.contractSum ?? 0;
  },

  offerIsUnconditional(offer: Offer5086, tender: Tender5086): boolean {
    return (
      offer.state === 'Unconditional'
      || (offer.isUnconditional ?? false)
      || getCalculator().isScoreBelowConditionalThreshold_BackendCalculation(
        offer,
      )
      || getCalculator().isScoreBelowConditionalThreshold_FrontendCalculation(
        offer,
        tender,
      )
    );
  },

  applyTerminationLetterAgreementSpecific(
    answers: AnswerSetBuilder,
    offer: Offer5086,
    tender: Tender5086,
    winningOffer: Offer5086 | undefined,
    _conditionalOffers: Offer[],
    _isUnconditional: boolean,
  ) {
    if (winningOffer) {
      // Winner's price points
      answers.addNumber(
        '91a4ddea-b512-4d57-9ed7-92a790fc1d61',
        winningOffer.data.bpqPriceScore ?? 0,
      );
      // Winner's quality score
      answers.addNumber(
        '33a23417-fdf8-4b6b-a921-06f4424ce6f9',
        winningOffer.data.bpqPointScore ?? 0,
      );
      // Winner's toal points
      answers.addNumber(
        '1cb29e8a-93b4-4514-8454-4fd8f3cf18b6',
        winningOffer.data.bpqScore ?? 0,
      );
    } else {
      // Numeric fields cannot be empty, otherwise DD will not accept the questionnaire as valid
      answers.add('91a4ddea-b512-4d57-9ed7-92a790fc1d61', '-');
    }
    // // Best price quality ratio, APV
    // if (tender.awardCriteriaType === 'BPQRatio') {
    //   console.log('prefills BPQ');
    //   const td = tender.data;
    //   // APV custom percentage for cost criteria
    //   if (String(td.bpqRatioPercentage) === 'Anden procentdel' && td.bpqCustomWeightingQualityCriteriaPercentage) {
    //     answers.addNumber('e3218b8e-d1ff-47ff-921c-a7b4a9d4cc95', (100 - td.bpqCustomWeightingQualityCriteriaPercentage));
    //   } else {
    //     answers.add('e3218b8e-d1ff-47ff-921c-a7b4a9d4cc95', td.bpqCostsPercentage?.toString() ?? '');
    //   }
    // } else {
    //   console.log('prefills NOT BPQ');
    // }

    // Offer's price points
    answers.addNumber(
      '6298432b-ebc0-4f9e-ae2d-fce9ec956565',
      offer.data.bpqPriceScore ?? 0,
    );
    // Offer's quality points
    answers.addNumber(
      'b41cc488-c4e0-4542-a094-caee2f3971e9',
      offer.data.bpqPointScore ?? 0,
    );
    // Offer's total points
    answers.addNumber(
      '3754bc3d-042c-4703-83ab-29dc3da3e703',
      offer.data.bpqScore ?? 0,
    );
  },

  /* @typescript-eslint/no-empty-function */
  applyEvaluationReportAgreementSpecific(
    answers: AnswerSetBuilder,
    tender: Tender5086,
    conditionalOffers: Offer5086[],
    unconditionalOffers: Offer5086[],
    cvrNumbers: Record<string, string>,
  ) {
    // Sets count for the offer-repeater
    // (This is a special-case hidden question)
    answers.addNumber(
      'ed80cdfe-d377-4402-b52e-d6d6c36bee08',
      conditionalOffers.length + unconditionalOffers.length,
    );

    // Award type: Best price / Quality
    if (tender.awardCriteriaType === 'BPQRatio') {
      const weightQuality = qualityRatioPercentage(tender);
      const weightPrice = 100 - weightQuality;
      answers.addNumber('568a6827-2afe-4ef5-9363-f31daf4c17f0', weightQuality);
      answers.addNumber('5b20e131-e518-4800-833c-0b0b1cac088d', weightPrice);
      answers.add(
        'e3c3370c-b6da-4ea1-9a7d-0b419c697fd2',
        tender.data.bpqCostsPercentage?.toString() ?? '',
      );

      // TODO: Map values for extended price span

      const addApvCriteria = (answerId: string) => {
        answers.addCheck('97e968cf-aa4b-4db5-bcf1-c9b09185ba82', answerId);
      };
      (tender.data.bpqCriteria ?? []).forEach((c) => {
        switch (c) {
          case 'SolutionDescription':
            addApvCriteria('ea3f1f38-b21c-48fe-8f94-f4ba5663ac55');
            break;
          case 'Functionality':
            addApvCriteria('44ef2d98-a6ab-46b8-89f2-7472c4bf8a7e');
            break;
          case 'Implementation':
            addApvCriteria('bb34ca26-e138-49f3-a21e-57e3793f0cb2');
            break;
          case 'OrganisationCooperation':
            addApvCriteria('fe036f0c-4572-4ee4-b7cd-4065aa99e6e0');
            break;
          default:
            throw new Error(`Cannot add unknown BPQ criteria ${String(c)}`);
        }
      });
    }

    // Indkomne tilbud (repeater ID's, will be postfixed with index)
    const companyNameQuestionId = '234c1e86-7b17-4f52-9b19-3df4ec7ee7f4';
    const companyCvrQuestionId = '9c34c3a2-9769-4299-8d30-3ddb7a042c96';
    const conditionalQuestionId = '34202a02-e5d1-430b-a158-a0bb94d1c39b';
    const conditionalTrueAnswerId = '6a4d2959-9539-40df-9287-fcf0e9af95d9';
    const conditionalFalseAnswerId = 'f5024533-1cef-426d-a0b0-c7b882757c33';
    const bpqTcoPriceQuestionId = 'b8e1767b-0dbd-48e4-9106-23f04f6a6026';
    const bpqPriceScoreQuestionId = '137277ce-4bc0-4d60-99b4-9cff824c301d';
    const bpqPointScoreQuestionId = '7a0ed686-0060-41da-b4c3-c3383b709a76';
    const bpqScoreQuestionId = 'a2089ef5-2dca-4e19-9d19-dfc208ca4315';
    const switchingCostsQuestionId = 'bd025696-80ce-4a74-8111-38b0434a8039';
    const switchingCostsNoteQuestionId = 'a22a1964-b3eb-422d-ad6b-f0d69d052cdc';

    const espdDocumentationApprovedQuestionId = '808fed60-eb55-4703-8a94-6d922fac771b';
    const espdDocumentationApprovedTrueAnswerId = '7a5f9f96-61a0-43b5-ad22-b4b22111084e';
    const espdDocumentationApprovedFalseAnswerId = 'b00d6794-0d40-471e-bb3e-9090f19736d0';

    // All offers
    const allOffers: Offer5086[] = [];
    allOffers.push(...conditionalOffers, ...unconditionalOffers);
    /* A repeater for all offers. Winning offer is special cased.
      If question ID's for winning offer is foo, other offers are foo_1, foo_2 etc.
    */
    allOffers.forEach((o: Offer5086, index: number) => {
      const od = o.data;
      if (index === 0) {
        answers.add(companyNameQuestionId, o.sender.companyName);
        answers.add(companyCvrQuestionId, cvrNumbers[o.sender.organizationNumber] ?? '');
        answers.addCheck(
          conditionalQuestionId,
          this.offerIsUnconditional(o, tender)
            ? conditionalFalseAnswerId
            : conditionalTrueAnswerId,
        );
        answers.addCurrency(
          bpqTcoPriceQuestionId,
          od.tcoCalculationResult?.tcoPrice ?? 0,
        );
        answers.addNumber(bpqPriceScoreQuestionId, od.bpqPriceScore ?? -1);
        answers.addNumber(bpqPointScoreQuestionId, od.bpqPointScore ?? -1);
        answers.addNumber(bpqScoreQuestionId, od.bpqScore ?? -1);
        if (tender.data.useSwitchingCosts) {
          answers.addCurrency(switchingCostsQuestionId, od.switchingCosts ?? 0);
          answers.add(
            switchingCostsNoteQuestionId,
            od.switchingCostsNote ?? '',
          );
        }
      } else {
        answers.add(`${companyNameQuestionId}_${index}`, o.sender.companyName);
        answers.add(`${companyCvrQuestionId}_${index}`, cvrNumbers[o.sender.organizationNumber] ?? '');
        answers.addCheck(
          `${conditionalQuestionId}_${index}`,
          this.offerIsUnconditional(o, tender)
            ? `${conditionalFalseAnswerId}_${index}`
            : `${conditionalTrueAnswerId}_${index}`,
        );
        answers.addCurrency(
          `${bpqTcoPriceQuestionId}_${index}`,
          od.tcoCalculationResult?.tcoPrice ?? 0,
        );
        answers.addNumber(
          `${bpqPriceScoreQuestionId}_${index}`,
          od.bpqPriceScore ?? -1,
        );
        answers.addNumber(
          `${bpqPointScoreQuestionId}_${index}`,
          od.bpqPointScore ?? -1,
        );
        answers.addNumber(`${bpqScoreQuestionId}_${index}`, od.bpqScore ?? -1);
        if (tender.data.useSwitchingCosts) {
          answers.addCurrency(`${switchingCostsQuestionId}_${index}`, od.switchingCosts ?? 0);
          answers.add(
            `${switchingCostsNoteQuestionId}_${index}`,
            od.switchingCostsNote ?? '',
          );
        }
      }
    });
    const winner = conditionalOffers[0];
    answers.addCheck(
      espdDocumentationApprovedQuestionId,
      winner?.documentationApproved
        ? espdDocumentationApprovedTrueAnswerId
        : espdDocumentationApprovedFalseAnswerId,
    );
  },
};
