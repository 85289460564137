<template>
  <CreateTenderDialog
    v-if="store.myTendersFetched"
    :tender="tender"
    :tender-stub="tenderStub"
    :create-button-disabled="!isDraftValid()"
    :createAsTemplate="createAsTemplate"
  >
    <template #create-tender-wizard-step>
      <CreateTenderWizardStep :createAsTemplate="createAsTemplate"></CreateTenderWizardStep>
    </template>
  </CreateTenderDialog>
</template>

<script setup lang="ts">
import { Tender, useStore } from '@dims/components';
import CreateTenderWizardStep from '@/components/create-tender/CreateTenderWizardStep.vue';
import { useStore5086 } from '@/store/store5086';
import { tenderStub as defaultTenderStub } from '@/services';

const { tender = null, createAsTemplate = false } = defineProps<{
  /** Only when the create/clone button is on an existing tender */
  tender?: Tender | null,
  createAsTemplate?: boolean }>();
const store = useStore();
const store5086 = useStore5086();

function isDraftValid() {
  return Boolean(store5086.draftTender?.description
    && store5086.draftTender.awardCriteriaType);
}

const tenderStub = { ...defaultTenderStub, agreementName: store.agreementId };

</script>
