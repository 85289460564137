<template>
  <div>
    <v-radio-group v-model="selectedVehicleType" :hide-details="true">
      <div class="d-flex align-center">
        <v-radio
          class="ml-6"
          density="compact"
          color="accent"
          :value="vehicleTypePeopleMover"
        >
          <template #label>
            {{ getLabel(vehicleTypePeopleMover) }}
            <DimsTooltip customClass="mb-2">
              <div>
                <p>Biler, indrettet til personbefordring af højest 9 personer, føreren medregnet.</p>
                <p>Svarende til indregistreringskategori M1.</p>
                <p>Kategorien indeholder biler med drivmidler som benzin, diesel, el, brint, gas, hybrid mv. samt diverse aftaler om reservedele, reparation, service og opbygninger i forbindelse med det konkrete indkøb.</p>
                <p>Du kan anskaffe fabriksnye biler og demobiler.</p>
              </div>
            </DimsTooltip>
          </template>
        </v-radio>

      </div>
      <div class="d-flex align-center">
        <v-radio
          class="ml-6"
          density="compact"
          color="accent"
          :value="vehicleTypeUtility"
        >
          <template #label>
            {{ getLabel(vehicleTypeUtility) }}
            <DimsTooltip customClass="mb-2">
              <div>
                <p>Biler, indrettet til:</p>
                <ul>
                  <li>
                    <b>Personbefordring</b> af mere end 9 personer, føreren medregnet, med en tilladt totalvægt på maks. 5.000 kg. Svarende til indregistreringskategori M2.
                  </li>
                  <br />
                  <li>
                    <b>Godsbefordring</b> med en totalvægt på ikke over 4.250 kg. for el drivmiddel og 3.500 kg. for andre drivmidler. Svarende til indregistreringskategori N1.<br /><br />
                    Kategorien indeholder biler med drivmidler som benzin, diesel, el, brint, gas, hybrid mv. samt diverse aftaler om reservedele, reparation, service og opbygninger i forbindelse med det konkrete indkøb.<br /><br />
                    Du kan anskaffe fabriksnye biler og demobiler.
                  </li>
                </ul>
              </div>
            </DimsTooltip>
          </template>
        </v-radio>
      </div>
    </v-radio-group>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { DimsTooltip } from '@dims/components';
import { VehicleType } from '@/models';
import VehicleTypesEnum, { vehicleTypeLabels } from '@/models/VehicleTypes';
import { useStore5086 } from '@/store/store5086';

const store5086 = useStore5086();
const leasingDisabled = ref(false);
const vehicleTypePeopleMover = VehicleType.PEOPLE_MOVER;
const vehicleTypeUtility = VehicleType.UTILITY_VEHICLE;

const selectedVehicleType = computed({
  get(): VehicleType | undefined {
    let vehicleType;
    if (store5086.draftTender) {
      vehicleType = store5086.draftTender.data.vehicleType;
    }
    return vehicleType ?? undefined;
  },
  set(vehicleType: VehicleType | undefined) {
    let value = vehicleType;
    if (vehicleType === VehicleTypesEnum.LEASING) {
      leasingDisabled.value = true;
      value = VehicleTypesEnum.PEOPLE_MOVER;
    }
    if (store5086.draftTender) {
      store5086.setDraftTender({
        ...store5086.draftTender,
        data: { ...store5086.draftTender.data, vehicleType: value },
      });
    }
  },
});

function getLabel(type: VehicleType): string {
  return vehicleTypeLabels(type);
}

</script>
