<template>
  <EvaluationOfferListBase
    :tender="tender"
    :offers="offers"
    :offersEvaluations="offersEvaluations"
    :additionalHeaders="additionalHeaders"
    :isLoadingOffers="isLoadingOffers"
    contractSumTooltip="Den samlet tilbudte pris inkl. optioner"
    @fetchOffers="fetchOffers"
  >
    <template #additional-columns="{ offer, isMobile }: { offer: Offer5086, isMobile: boolean }">
      <template v-if="isMobile">
        <li class="flex-item" data-label="Skifteomkostninger" v-if="tender.data.useSwitchingCosts">
          <SwitchingCostsDialog
            :offer="offer"
            :readonly="
              tender.state !== TenderStateEnum.Evaluate
                || offer.readyForAwarding
                || hasRequestedDocumentationFromWinningOffer
            "
            @offerChanged="fetchOffers"
          />
          <br />
          <div class="d-flex">
            {{
              (offer.data.switchingCosts || offer.data.switchingCosts === 0)
                ? toCurrencyFormatDK2(offer.data.switchingCosts)
                : "Skifteomkostninger er ikke indtastet"
            }}
          </div>
        </li>
        <li class="flex-item" data-label="Evalueringteknisk pris inkl. skifteomkostninger" v-if="tender.data.useSwitchingCosts">
          {{ toCurrencyFormatDK2((offer.data.tcoCalculationResult?.tcoPrice ?? 0) + (offer.data.switchingCosts ?? 0)) }}
        </li>
      </template>
      <template v-else>
        <td v-if="tender.data.useSwitchingCosts">
          <SwitchingCostsDialog
            :offer="offer"
            :readonly="
              tender.state !== TenderStateEnum.Evaluate
                || offer.readyForAwarding
                || hasRequestedDocumentationFromWinningOffer
            "
            @offerChanged="fetchOffers"
          />
          <br />
          <div class="d-flex">
            {{
              (offer.data.switchingCosts || offer.data.switchingCosts === 0) ?? false !== false
                ? toCurrencyFormatDK2(offer.data.switchingCosts)
                : "Skifteomkostninger er ikke indtastet"
            }}
          </div>
        </td>
        <td v-if="tender.data.useSwitchingCosts">
          {{ toCurrencyFormatDK2((offer.data.tcoCalculationResult?.tcoPrice ?? 0) + (offer.data.switchingCosts ?? 0)) }}
        </td>
      </template>
    </template>
    <template #tco></template>
    <template #bpq="{ offer, index }">
      <BpqDocumentationDialog
        :offer="offer"
        :offerEvaluation="getOfferEvaluation(offer.id)"
        :tender="tender"
        :conditionalOffers="conditionalOffers"
        :index="index"
      />
    </template>
  </EvaluationOfferListBase>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import {
  Offer,
  EvaluationOfferListBase,
  toCurrencyFormatDK2,
  TenderStateEnum,
  OfferEvaluation,
} from '@dims/components';
import BpqDocumentationDialog from './BpqDocumentationDialog.vue';
import { Offer5086, Tender5086 } from '@/models';
import SwitchingCostsDialog from './SwitchingCostsDialog.vue';
import { getCalculator } from '@/services';

const emit = defineEmits<{
  fetchOffers: []
  }>();
const { tender, offers, isLoadingOffers = false, offersEvaluations } = defineProps<{
  tender: Tender5086,
  offers: Offer5086[],
  offersEvaluations: OfferEvaluation[],
  isLoadingOffers?: boolean,
}>();
const calculator = getCalculator();

const additionalHeaders = ref(tender.data.useSwitchingCosts
  ? [
    { text: 'Skifteomkostninger', sortable: false },
    { text: 'Evalueringteknisk pris inkl. skifteomkostninger', sortable: false },
  ]
  : []);

function getOfferEvaluation(offerId: string) {
  return offersEvaluations.find((oe) => oe.offerId === offerId);
}

function fetchOffers() {
  emit('fetchOffers');
}

const conditionalOffers = computed((): Offer5086[] => {
  // Get offers to be shown in list of conditional offers
  const ofs = offers.filter(
    (o) => o.state !== 'Unconditional'
        && !(o.isUnconditional ?? false)
        && !calculator.isScoreBelowConditionalThreshold_BackendCalculation(
          o,
        )
        && !calculator.isScoreBelowConditionalThreshold_FrontendCalculation(
          o,
          tender,
        ),
  );
  console.info('get conditional offers returns: ', ofs);
  if (tender.awardCriteriaType === 'TCO') {
    return ofs;
  }
  return calculator.sortBPQBestFirst(ofs, tender);
});

const winningOffer = computed((): Offer | undefined => {
  if (conditionalOffers.value.length === 0) {
    return undefined;
  }
  return conditionalOffers.value[0];
});

const hasRequestedDocumentationFromWinningOffer = computed((): boolean => winningOffer.value?.documentationRequested ?? false);

</script>
