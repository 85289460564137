import { OfferEvaluation, OfferEvaluationData } from '@dims/components';
import { BpqScores, OfferData5086 } from '@/models/Offer';
import { TenderData5086 } from '@/models/Tender';

export type BPQCriteriaEnum =
 | 'SolutionDescription'
 | 'Functionality'
 | 'Implementation'
 | 'OrganisationCooperation';

const categories: [BPQCriteriaEnum, string][] = [
  ['SolutionDescription', 'Løsningsbeskrivelse'],
  ['Functionality', 'Funktionalitet'],
  ['Implementation', 'Implementering'],
  ['OrganisationCooperation', 'Organisation, bemanding og samarbejde'],
];

export default {
  getCategories(tenderData: TenderData5086, offerEvaluation: OfferEvaluation) {
    return categories
      .filter((c) => {
        const [key, _label] = c;
        return this.isScoreIncluded(tenderData, key);
      })
      .map((c) => {
        const [key, label] = c;

        // Verbose version to avoid linting errors
        let score = 0;
        let reason = '';

        if (offerEvaluation.data) {
          const offerEvaluationElement = this.getOfferEvaluationElement(offerEvaluation.data, key);

          if (offerEvaluationElement) {
            score = offerEvaluationElement.score ?? 0;
            reason = offerEvaluationElement.reason ?? '';
          }
        }

        return {
          key,
          label,
          score,
          weight: this.getWeight(tenderData, key) ?? 0,
          reason,
        };
      });
  },

  getEmptyCategories(tenderData: TenderData5086) {
    return categories
      .filter((c) => {
        const [key, _label] = c;
        return this.isScoreIncluded(tenderData, key);
      })
      .map((c) => {
        const [key, label] = c;
        return {
          key,
          label,
          score: 0,
          weight: 0,
          reason: '',
        };
      });
  },
  getOfferEvaluationElement(offerEvaluationData: OfferEvaluationData[], key: BPQCriteriaEnum) {
    return offerEvaluationData.find((o: OfferEvaluationData) => o.text === key);
  },
  getWeight(tenderData: TenderData5086, key: BPQCriteriaEnum) {
    const propertyName: keyof TenderData5086 = `bpqCriteria${key}`;
    return tenderData[propertyName];
  },
  isScoreIncluded(tenderData: TenderData5086, key: BPQCriteriaEnum) {
    return tenderData.bpqCriteria?.includes(key);
  },
  getScorePropertyName(key: BPQCriteriaEnum): keyof BpqScores {
    return `bpq${key}Score`;
  },
  getOfferScore(offerData: OfferData5086, key: BPQCriteriaEnum) {
    const propertyName = this.getScorePropertyName(key);
    return offerData[propertyName];
  },
  setOfferScore(offerData: OfferData5086, key: BPQCriteriaEnum, value: number) {
    const scorePropertyName = this.getScorePropertyName(key);
    // eslint-disable-next-line no-param-reassign
    offerData[scorePropertyName] = value;
  },
};
