import { PricePointDropdownValues, basePricePointDropdownValuesFromGroup } from '@dims/components';

function pricePointDropdownValuesFromGroup(groupId: string) {
  switch (groupId) {
    case '11dcd6f5-46e5-48db-8f78-3f77d4069436': // 50.86 Netværksprodukter og -løsninger / Switche
    case 'dfe2c92b-5436-4506-9502-d43bf6987ee2': // 50.86 Netværksprodukter og -løsninger / Routere
    case '6a35e2f5-e607-4eb1-8ca7-f0c10bc9d808': // 50.86 Netværksprodukter og -løsninger / WIFI (Access points)
    {
      // Dropdown "B" in "Priser Mock-up v4.xlsx"
      return ['Pr. år', 'Engangsbetaling for 1 år', 'Engangsbetaling for 3 år', 'Engangsbetaling for 5 år'];
    }
    case '3faf2739-a351-4e71-8b28-355c8e24ca97': // 50.86 Netværksprodukter og -løsninger / WIFI (Controller)
    {
      // Dropdown "B"+"C" in "Priser Mock-up v4.xlsx"
      return ['Pr. år', 'Engangsbetaling for 1 år', 'Engangsbetaling for 3 år', 'Engangsbetaling for 5 år', 'Pr. stk', 'Pr. stk. i kvanter af 50', 'Pr. stk. i kvanter af 100', 'Pr. stk. i kvanter af 200', 'Pr. stk. i kvanter af 1.000'];
    }
    case '66a56247-8457-4d66-9c16-415adcf90fec': // 50.86 Overvågningsprodukter og -løsninger / Videoovervågning
    {
      // Dropdown "D"+"E"+"F" in "Priser Mock-up v4.xlsx"
      return ['Pr. år', 'Pr. kamera i netværket pr. år', 'Engangsbetaling for 1 år', 'Engangsbetaling for 3 år', 'Engangsbetaling for 5 år', 'Produktpris'];
    }
    case '53f195dc-1b97-44b2-8fc3-2bd9663afae5': // 50.86 Overvågningsprodukter og -løsninger / Skalsikring
    case 'f6ec01fa-9f67-47ee-8502-e8c7459655df': // 50.86 Overvågningsprodukter og -løsninger / Adgangskontrol
    case '563171de-2456-44e6-8f99-ff63d68e8900': // 50.86 Overvågningsprodukter og -løsninger / Alarmsystemer
    {
      // Dropdown "G" + "B" in "Priser Mock-up v4.xlsx"
      return ['Pr. enhed', 'Pr. systemlicens', 'Pr. sensorer i kvanter af 50', 'Pr. sensorer i kvanter af 100', 'Pr. sensorer i kvanter af 200', 'Pr. år', 'Engangsbetaling for 1 år', 'Engangsbetaling for 3 år', 'Engangsbetaling for 5 år'];
    }
    case 'd6b7f4f4-15b0-4fdd-9a9e-771593fed2a9': // 50.86 Overvågningsprodukter og -løsninger / Bodycam
    {
      // Dropdown with bodycam values + "B" in "Priser Mock-up v4.xlsx"
      return ['Pr. abonnement', 'Pr. systemlicens', 'Pr. licens', 'Pr. integration', 'Pr. år', 'Engangsbetaling for 1 år', 'Engangsbetaling for 3 år', 'Engangsbetaling for 5 år'];
    }
    case 'ef9d8e56-babd-4e54-82b1-fa5711718201': // 50.86 It-sikkerhedsprodukter og -løsninger / DDOS
    {
      // Dropdown "H" + "B" in "Priser Mock-up v4.xlsx"
      return ['Pr. systemlicens', 'Pr. IP-adresse/Internet domæne', 'Pr. år', 'Engangsbetaling for 1 år', 'Engangsbetaling for 3 år', 'Engangsbetaling for 5 år'];
    }
    case 'ca05f233-22eb-4b3f-8305-486fd54402cd': // 50.86 It-sikkerhedsprodukter og -løsninger / Firewalls
    {
      // Dropdown "I"+"J" + "B" in "Priser Mock-up v4.xlsx"
      return ['Pr. AD bruger', 'Pr. netværkskomponent', 'Pr. GByte trafik', 'Pr. licens', 'Pr. år', 'Engangsbetaling for 1 år', 'Engangsbetaling for 3 år', 'Engangsbetaling for 5 år'];
    }
    case '2d4fddca-60d0-497d-94e8-507b46e00c44': // 50.86 It-sikkerhedsprodukter og -løsninger / SIEM
    {
      // Dropdown "K" + "B" in "Priser Mock-up v4.xlsx"
      return ['Pr. systemlicens', 'Pr. netværkskomponent', 'Pr. GByte trafik', 'Pr. år', 'Engangsbetaling for 1 år', 'Engangsbetaling for 3 år', 'Engangsbetaling for 5 år'];
    }
    case '23a752e4-961f-4a6a-8ee4-88733b7b9a19': // 50.86 It-sikkerhedsprodukter og -løsninger / SOC
    {
      // Dropdown values + "B" in "Priser Mock-up v4.xlsx"
      return ['Pr. licens', 'Pr. år', 'Engangsbetaling for 1 år', 'Engangsbetaling for 3 år', 'Engangsbetaling for 5 år'];
    }
    case '3f6561ba-57bb-45e0-8824-1729ac705d26': // 50.86 Unified Communications Produkt / Videokonference
    {
      // Dropdown "L"+"M" in "Priser Mock-up v4.xlsx"
      return ['Pr. år', 'Pr. bruger', 'Pr. 10 samtidige', 'Pr. 50 samtidige', 'Pr. 100 samtidige'];
    }
    case 'cbd81a37-d730-4084-8487-225bc119772b': // 50.86 IoT / IoT
    {
      // Dropdown values +" B" in "Priser Mock-up v4.xlsx"
      return ['Pr. licens', 'Pr. systemlicens', 'Pr. IoT enhed', 'Pr. Gbps kapacitet', 'Pr. IoT enhed i kvanter af 500', 'Pr. IoT enhed i kvanter af 1000', 'Pr. IoT enhed i kvanter af 2000', 'Pr. år', 'Engangsbetaling for 1 år', 'Engangsbetaling for 3 år', 'Engangsbetaling for 5 år'];
    }
    default: {
      return basePricePointDropdownValuesFromGroup(groupId);
    }
  }
}

const pricePointDropdownValues: PricePointDropdownValues = {
  getPricePointDropdownValues(_type, _configurationId, groupId) {
    return pricePointDropdownValuesFromGroup(groupId);
  },
};

export default pricePointDropdownValues;
