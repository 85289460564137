import { TemplateQuestionPanelState, Tab, TabType, DeepQuestionnaire } from '@dims/components';

const customerSpecificationTabs: Tab[] = [{
  title: 'Basis',
  section: 0,
  panelState: TemplateQuestionPanelState.INIT,
},
{
  title: 'Puljekøb',
  section: 1,
  panelState: TemplateQuestionPanelState.INIT,
},
];

function supplierSpecificationTabs(p: [number, number]) {
  return [
    {
      title: 'Svar på nonfunktionelle krav',
      section: p[0],
      panelState: TemplateQuestionPanelState.INIT,
    },
    {
      title: 'Svar på evalueringskrav',
      section: p[1],
      panelState: TemplateQuestionPanelState.INIT,
    },
  ];
}

function supplierOfferTabs(p: number) {
  return [
    {
      title: 'Afgiv priser',
      section: p,
      panelState: TemplateQuestionPanelState.INIT,
    },
  ];
}

// TODO: These numbers must match the proper section in questionnaire
const nonFunctionalSectionId = 'd30ca7de-d6b2-466e-afd2-7232118919b6'; // Kontraktkrav
const evaluationSectionId = 'd30ca7de-d6b2-466e-afd2-7232118919b6'; // Evalueringskrav
const priceSectionId = 'd30ca7de-d6b2-466e-afd2-7232118919b6'; // Tilbudsliste
let supplierOfferTabNumber = 3;
let supplierSpecificationTabNumbers: [number, number] = [1, 2];

export function setSupplierSpecificationTabs(offerQuestionnaire: DeepQuestionnaire|null):number[] {
  supplierSpecificationTabNumbers = [offerQuestionnaire?.questions.findIndex((q) => q.questionId === nonFunctionalSectionId) ?? 1,
    offerQuestionnaire?.questions.findIndex((q) => q.questionId === evaluationSectionId) ?? 2];
  return supplierSpecificationTabNumbers;
}

export function setSupplierPriceTab(offerQuestionnaire: DeepQuestionnaire|null):number {
  supplierOfferTabNumber = offerQuestionnaire?.questions.findIndex((q) => q.questionId === priceSectionId) ?? 3;
  return supplierOfferTabNumber;
}

const questionnaireTabConfig = {
  getTabs: (type: TabType): Tab[] => {
    switch (type) {
      case 'CustomerSpecification':
        return customerSpecificationTabs;
      case 'SupplierSpecification':
        return supplierSpecificationTabs(supplierSpecificationTabNumbers);
      case 'SupplierOffer':
        return supplierOfferTabs(supplierOfferTabNumber);
      default:
        return [];
    }
  },
};

export default questionnaireTabConfig;
